import React from 'react';
import './About.css';

function About() {
  return (
    <div className="container">
      <main role="main">
        <div id="about-me">
          <div className="about-me-box">
            <img src={require('../assets/main-image-resize.jpg')} alt="Rizky Abraham" />
          </div>
          <div className="about-me-box">
            <h2>About <span className="orange-font">Me</span></h2>
            <p className="headline">Full Stack Developer & UI/UX Designer</p>
            <p>I am a passionate developer with experience in both front-end and back-end technologies. I love creating beautiful and functional web applications that provide great user experiences.</p>
            <div className="mt-4">
              <a href="/resume" className="btn btn-primary mr-2">View Resume</a>
              <a href="/contact" className="btn btn-outline-primary">Contact Me</a>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default About; 