import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <div className="container">
      <main role="main">
        <div id="contact">
          <h2 className="text-center">Contact <span className="orange-font">Me</span></h2>
          <div className="contact-container">
            <div className="contact-info">
              <h3>Get in Touch</h3>
              <p>Feel free to reach out to me for any questions or opportunities!</p>
              <div className="contact-details">
                <p><i className="fas fa-envelope"></i> email@example.com</p>
                <p><i className="fas fa-phone"></i> +1 (123) 456-7890</p>
                <p><i className="fas fa-map-marker-alt"></i> Your Location</p>
              </div>
            </div>
            <div className="contact-form">
              <form>
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="Your Name" required />
                </div>
                <div className="form-group">
                  <input type="email" className="form-control" placeholder="Your Email" required />
                </div>
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="Subject" required />
                </div>
                <div className="form-group">
                  <textarea className="form-control" rows="5" placeholder="Your Message" required></textarea>
                </div>
                <button type="submit" className="btn btn-primary">Send Message</button>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Contact; 