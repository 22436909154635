import React, { useState } from 'react';
import './Home.css';
import uxDesignImage from '../assets/ux-design.svg';
import bloggingImage from '../assets/blogging.svg';
import securityImage from '../assets/security.svg';
import mainImage from '../assets/main-image-resize.jpg';

function Home() {
  const [showDeveloperModal, setShowDeveloperModal] = useState(false);
  const [showUiUxModal, setShowUiUxModal] = useState(false);
  const [showItTechModal, setShowItTechModal] = useState(false);

  return (
    <div className="container">
      <main role="main">
        <div id="home" className="text-center site-hero">
          <div className="site-hero-text">
            <h1>Hello, I'm<br /> <strong><span>Rizky</span> Abraham</strong></h1>
            <p>and this is My Website</p>
          </div>
        </div>

        <div id="projects">
          <article className="project-box web-developer">
            <h3>Full Stack Developer</h3>
            <img src={bloggingImage} alt="web design" className="img-fluid" />
            <a href="#" className="view-button" onClick={() => setShowDeveloperModal(true)}>
              View sample projects
            </a>
          </article>

          <article className="project-box ui-ux">
            <h3>Graphic Design</h3>
            <img src={uxDesignImage} alt="graphic design" className="img-fluid" />
            <a href="#" className="view-button" onClick={() => setShowUiUxModal(true)}>
              View sample projects
            </a>
          </article>

          <article className="project-box devops">
            <h3>DevOps</h3>
            <img src={securityImage} alt="A man with a shield" className="img-fluid" />
            <a href="#" className="view-button" onClick={() => setShowItTechModal(true)}>
              View skills
            </a>
          </article>
        </div>

        {/* Developer Modal */}
        {showDeveloperModal && (
          <div className="modal show" style={{ display: 'block' }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Web &amp; Software Developer</h5>
                  <button type="button" className="close" onClick={() => setShowDeveloperModal(false)}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>Web Developer (C#, .NET, Razor, MVC)</p>
                  <ul className="list-group">
                    <li className="list-group-item">
                      <a href="https://github.com/Abraham-Rizky/CIT365-F2020-AbrahamRizky" target="_blank" rel="noopener noreferrer">GitHub Link</a>
                    </li>
                  </ul>
                  <p>Java</p>
                  <ul className="list-group">
                    <li className="list-group-item">
                      <a href="https://github.com/Abraham-Rizky/android-webview" target="_blank" rel="noopener noreferrer">GitHub Link - Android App</a>
                    </li>
                    <li className="list-group-item">
                      <a href="https://github.com/Sirrine-Jonathan/Afghan_Trail" target="_blank" rel="noopener noreferrer">GitHub Link - Text based game</a>
                    </li>
                  </ul>
                  <p>Web Developer (SASS and SVG)</p>
                  <ul className="list-group">
                    <li className="list-group-item">
                      <a href="https://abraham-rizky.github.io/zengarden-final/" target="_blank" rel="noopener noreferrer">SASS and SVG animation</a>
                    </li>
                  </ul>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => setShowDeveloperModal(false)}>Close</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* UI/UX Modal */}
        {showUiUxModal && (
          <div className="modal show" style={{ display: 'block' }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Graphic Designer</h5>
                  <button type="button" className="close" onClick={() => setShowUiUxModal(false)}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>InDesign, Illustrator, and Photoshop</p>
                  <ul className="list-group">
                    <li className="list-group-item">
                      <a href="https://github.com/Abraham-Rizky/graphic_design" target="_blank" rel="noopener noreferrer">GitHub Link</a>
                    </li>
                  </ul>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => setShowUiUxModal(false)}>Close</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* IT Tech Modal */}
        {showItTechModal && (
          <div className="modal show" style={{ display: 'block' }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">DevOps Skills</h5>
                  <button type="button" className="close" onClick={() => setShowItTechModal(false)}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>Languages</p>
                  <ul className="list-group">
                    <li className="list-group-item">Java, JavaScript, C#</li>
                  </ul>
                  <p>Frameworks</p>
                  <ul className="list-group">
                    <li className="list-group-item">Spring Boot, React, .NET, Gradle, Fastlane</li>
                  </ul>
                  <p>DevOps/Tools</p>
                  <ul className="list-group">
                    <li className="list-group-item">Azure Cloud & DevOps, Jenkins, Git, ELK Stack</li>
                    <li className="list-group-item">Azure Key Vault, PCF, Terraform, Cloudflare</li>
                    <li className="list-group-item">Azure APIM, API OPS, Artifactory, AKS</li>
                  </ul>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => setShowItTechModal(false)}>Close</button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div id="resume">
          <h2 className="text-center">My <span className="font-weight-bolder orange-font">Resume</span></h2>
          <div className="resume-container">
            <div className="resume-education">
              <h3>Education</h3>
              <div className="resume-box">
                <p>August 2017 - April 2021</p>
                <h3>Bachelor in Computer Science Information Technology</h3>
                <p>Majoring in Computer Science IT with minor in Web Development</p>
                <p>Brigham Young University Idaho</p>
              </div>
              <div className="resume-box">
                <p>April 2018</p>
                <h3>Front-End Web Developer Scholarship</h3>
                <p>Awarded with a scholarship from Udacity</p>
                <p>Udacity partnership with Google</p>
              </div>
              <h3>Licenses & Certifications</h3>
              <div className="resume-box">
                <h3>SAFe® 5 Practitioner | SAFe® 5 DevOps Practitioner</h3>
                <p>Scaled Agile, Inc.</p>
              </div>
              <h3>Honors & Awards</h3>
              <div className="resume-box">
                <h3>GM Service Solution Monthly Excellence Award</h3>
                <p>Awarded by CIO - April & December</p>
                <p>General Motors</p>
              </div>
            </div>
            <div className="resume-experience">
              <h3>Experience</h3>
              <div className="resume-box">
                <p>Oct 2023 - Present</p>
                <h3>DevOps Engineer</h3>
                <p>Standardize and automate CI/CD processes across multiple pipelines. Support deployment to over 550 sites across 44 states.</p>
                <p>PrePass</p>
              </div>
              <div className="resume-box">
                <p>Jan 2022 - Oct 2023</p>
                <h3>Backend Developer & DevOps</h3>
                <p>Maintain and develop apps for one of top fortune 500 companies</p>
                <p>General Motors</p>
              </div>
              <div className="resume-box">
                <p>May 2012 - Jan 2022</p>
                <h3>Environmental Services Manager</h3>
                <p>Lead and manage over 300+ workforce in 5 star and 5 diamond property.</p>
                <p>Bellagio Resort and Casino</p>
              </div>
              <div className="resume-box">
                <p>March 2018 - August 2018</p>
                <h3>Web Developer</h3>
                <p>Develop websites for clients using developer tools such as WordPress, PHP, SQL, and JavaScript.</p>
                <p>Clickitsocial</p>
              </div>
            </div>
          </div>
        </div>

        <div id="about-me">
          <div className="about-me-box">
            <img src={mainImage} alt="picture of Rizky Abraham" style={{ maxWidth: '100%', height: 'auto' }} />
          </div>
          <div className="about-me-box">
            <h2>About<strong className="orange-font"> Me</strong></h2>
            <p className="headline"><em>"My goal is to excel and innovate in the DevOps and Cloud Engineering space, leveraging my technical expertise to drive efficient and scalable solutions."</em></p>
            <p>A curious and hard-working professional from Indonesia, currently working as a DevOps Engineer in the Phoenix Metropolitan Area. Graduated from Brigham Young University Idaho with a major in Computer Science Information Technology and a minor in Web Development.</p>
            <div className="button-container">
              <a href="#" className="view-button" onClick={(e) => {
                e.preventDefault();
                document.getElementById('get-in-touch').scrollIntoView({ behavior: 'smooth' });
              }}>Hire me</a>
              <a href={require('../assets/My Resume- Rizky Abraham.pdf')} className="view-button" download>Download resume</a>
            </div>
          </div>
        </div>

        <div className="container-fluid" id="get-in-touch">
          <div className="row">
            <div className="col-md-12 mb-5">
              <div className="section-heading text-center">
                <h2>Get <strong className="orange-font">In Touch</strong></h2>
              </div>
            </div>

            <div className="col-md-7 mb-5 mb-md-0 my-form">
              <h3 className="mb-5">Contact Me</h3>
              <form action="https://formspree.io/f/xeqvojbv" method="post" className="site-form">
                <div className="form-group">
                  <input type="text" name="Name" className="form-control px-3 py-4" placeholder="Your Name" required />
                </div>
                <div className="form-group">
                  <input type="text" name="Email" className="form-control px-3 py-4" placeholder="Your Email" required />
                </div>
                <div className="form-group">
                  <input type="tel" name="Phone" className="form-control px-3 py-4" placeholder="Your Phone" required />
                </div>
                <div className="form-group mb-5">
                  <textarea name="Message" className="form-control px-3 py-4" cols="30" rows="10" placeholder="Write a Message" required></textarea>
                </div>
                <input type="submit" className="btn btn-primary px-4 py-3 submit-button" value="Submit" />
              </form>
            </div>
            <div className="col-md-5">
              <h3 className="mb-5">My Contact Details</h3>
              <ul className="site-contact-details">
                <li>
                  <span className="text-uppercase green-font">EMAIL</span><br />
                  rizkyabraham88@gmail.com
                </li>
                <li className="social-icons">
                  <a href="https://www.linkedin.com/in/rizky-abraham-378105184" className="social-item" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-linkedin"></i>
                  </a>
                  <a href="https://github.com/Abraham-Rizky" className="social-item" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-github"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home; 