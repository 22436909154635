import React, { useState } from 'react';
import './Navigation.css';

function Navigation() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollToSection = (sectionId, buttonSelector) => {
    if (buttonSelector) {
      // For project sections, scroll directly to the specific article
      const button = document.querySelector(buttonSelector);
      if (button) {
        const article = button.closest('article');
        if (article) {
          article.scrollIntoView({ behavior: 'smooth' });
          // Add pulse animation
          setTimeout(() => {
            button.classList.add('pulse');
            setTimeout(() => {
              button.classList.remove('pulse');
            }, 1000);
          }, 500);
        }
      }
    } else {
      // For home section, scroll to the section
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
    setIsOpen(false); // Close mobile menu
  };

  return (
    <nav className="navbar">
      <div className="nav-container">
        <button 
          className={`hamburger ${isOpen ? 'active' : ''}`} 
          onClick={toggleMenu} 
          aria-label="Toggle menu"
        >
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
        </button>
        <ul className={`nav-menu ${isOpen ? 'active' : ''}`}>
          <li className="nav-item">
            <a href="#" className="nav-link" onClick={(e) => {
              e.preventDefault();
              scrollToSection('home');
            }}>HOME</a>
          </li>
          <li className="nav-item">
            <a href="#" className="nav-link" onClick={(e) => {
              e.preventDefault();
              scrollToSection('projects', '.web-developer .view-button');
            }}>FULL STACK DEV</a>
          </li>
          <li className="nav-item">
            <a href="#" className="nav-link" onClick={(e) => {
              e.preventDefault();
              scrollToSection('projects', '.ui-ux .view-button');
            }}>GRAPHIC DESIGNER</a>
          </li>
          <li className="nav-item">
            <a href="#" className="nav-link" onClick={(e) => {
              e.preventDefault();
              scrollToSection('projects', '.devops .view-button');
            }}>DEVOPS</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navigation; 