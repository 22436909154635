import React from 'react';
import './Resume.css';

function Resume() {
  return (
    <div className="container">
      <main role="main">
        <div id="resume">
          <h2 className="text-center">My <span className="orange-font">Resume</span></h2>
          <div className="resume-container">
            <div className="resume-experience">
              <h3 className="green-font">Experience</h3>
              <div className="resume-box">
                <h3>Full Stack Developer</h3>
                <p>Company Name</p>
                <p>2020 - Present</p>
                <p>Description of your role and responsibilities</p>
              </div>
              <div className="resume-box">
                <h3>UI/UX Designer</h3>
                <p>Company Name</p>
                <p>2018 - 2020</p>
                <p>Description of your role and responsibilities</p>
              </div>
            </div>
            <div className="resume-education">
              <h3 className="green-font">Education</h3>
              <div className="resume-box">
                <h3>Degree Name</h3>
                <p>University Name</p>
                <p>2014 - 2018</p>
                <p>Description of your education</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Resume; 